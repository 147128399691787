import { Component, HostBinding, Input } from '@angular/core';
import { toBooleanProperty } from '@client-portal/functions/transformers';

@Component({
  selector: 'ui-icon',
  template: '{{icon}}',
  standalone: true,
  styles: `
    :host {
      display: inline-block;
      user-select: none;
    }
  `,
})
export class IconComponent {
  @Input({ required: true }) public icon!: string;

  @Input({ transform: toBooleanProperty })
  @HostBinding('class.filled')
  public filled = false;

  @HostBinding('class') public class = 'material-symbols-outlined';
}
